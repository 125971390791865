import { mapAccentColorFromDto } from "@/elements/accent-color/accent-color.mapper";
import type {
  PropertyTheme,
  PropertyThemeDto,
} from "@/elements/property/theme/property-theme";

export const mapPropertyThemeFromDto = (
  propertyThemeDto: PropertyThemeDto,
): PropertyTheme => ({
  accentColor: mapAccentColorFromDto(propertyThemeDto.accentColour),
});
