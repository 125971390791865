import type { Property } from "@/elements/property/property";
import type { Widget } from "@/elements/widget/widget";

export const findWidgetPropertyById = (
  { properties }: Widget,
  propertyId: string,
): Property | undefined => properties.find(({ id }) => id === propertyId);

export const findWidgetPrimaryProperty = (
  widget: Widget,
): Property | undefined => {
  for (const propertyIds of widget.rankOrderedPropertyIds) {
    for (const property of widget.properties) {
      if (propertyIds.includes(property.id)) {
        return property;
      }
    }
  }

  return;
};

export const findWidgetPropertyByIdOrFail = (
  widget: Widget,
  propertyId: string,
): Property => {
  const property = findWidgetPropertyById(widget, propertyId);

  if (!property) {
    throw new Error(
      `Could not find property ${propertyId} within widget ${widget.id}`,
    );
  }

  return property;
};

export const findWidgetPrimaryPropertyOrFail = (widget: Widget): Property => {
  const primaryProperty = findWidgetPrimaryProperty(widget);

  if (!primaryProperty) {
    throw new Error(`Could not find primary property for widget ${widget.id}`);
  }

  return primaryProperty;
};
