import type {
  WidgetElementsBookingElementDialog,
  WidgetElementsBookingElementDialogDto,
} from "@/elements/widget/elements/booking-element/dialog/widget-elements-booking-element-dialog";

export const mapWidgetElementsBookingElementDialogFromDto = (
  widgetElementsBookingElementDialogDto: WidgetElementsBookingElementDialogDto,
): WidgetElementsBookingElementDialog => ({
  enterSide: widgetElementsBookingElementDialogDto.enterSide,
});
