export const makePortalWebUnitSelectionUrl = (
  widgetId: string,
  propertyId: string | undefined,
): string => {
  const url = new URL(
    `${import.meta.env.VITE_PORTAL_WEB_ORIGIN}/widgets/${widgetId}/properties/${propertyId ? `${propertyId}/` : ""}unit-selection`,
  );

  url.searchParams.append(
    "elements",
    JSON.stringify({ hostUrl: window.location.host }),
  );

  return url.toString();
};
