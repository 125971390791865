import { localized, msg } from "@lit/localize";
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";

import { baseStyles } from "@/elements/assets/css/base";
import { BookingElementController } from "@/elements/booking-element/booking-element.controller";
import type { Property } from "@/elements/property/property";
import { WidgetElementsBookingElementButtonPositionAlignment } from "@/elements/widget/elements/booking-element/button/widget-elements-booking-element-button";
import { WidgetController } from "@/elements/widget/widget.controller";

@localized()
@customElement("bloom-booking-button")
export class BookingButtonElement extends LitElement {
  private widgetController = new WidgetController(this);
  private bookingElementController = new BookingElementController(this);

  @property({ attribute: "is-inline", type: Boolean })
  private isInline = false;

  @property({ attribute: "property-id" })
  private propertyId?: string;

  private get property(): Property {
    return this.propertyId
      ? this.widgetController.findPropertyById(this.propertyId)
      : this.widgetController.primaryProperty;
  }

  private get positionClasses(): string {
    if (this.isInline) {
      return "";
    }

    const { x, y } = this.widgetController.bookingElement.button.position;

    return `fixed z-[998] ${
      x === WidgetElementsBookingElementButtonPositionAlignment.Start
        ? "left-2"
        : "right-2"
    } ${
      y === WidgetElementsBookingElementButtonPositionAlignment.Start
        ? "top-2"
        : "bottom-2"
    }`;
  }

  protected override render() {
    if (!this.widgetController.isInitialized) {
      return;
    }

    const { hex, contrastHex, isHighLuminance } =
      this.widgetController.widget.elements.accentColor ??
      this.property.theme.accentColor;

    return html`
      <button
        class="${this.positionClasses} ${isHighLuminance
          ? "border border-slate-900"
          : "shadow-md"} rounded-lg px-6 py-3 font-medium outline-none transition-[filter] hover:brightness-95 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-slate-800 active:brightness-90"
        style="${styleMap({ backgroundColor: hex, color: contrastHex })}"
        @click=${() => {
          this.propertyId
            ? this.bookingElementController.setActivePropertyId(this.propertyId)
            : this.bookingElementController.resetActivePropertyId();

          this.bookingElementController.openDialog();
        }}
      >
        ${msg("Book Now")}
      </button>
    `;
  }

  public static override styles = [baseStyles];

  public static override shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    delegatesFocus: true,
  };
}
