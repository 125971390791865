export enum WidgetElementsBookingElementDialogEnterSide {
  Left = "left",
  Right = "right",
}

export interface WidgetElementsBookingElementDialog {
  enterSide: WidgetElementsBookingElementDialogEnterSide;
}

export type WidgetElementsBookingElementDialogDto =
  WidgetElementsBookingElementDialog;
