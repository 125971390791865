import type {
  AccentColor,
  AccentColorDto,
} from "@/elements/accent-color/accent-color";

export const mapAccentColorFromDto = (
  accentColorDto: AccentColorDto,
): AccentColor => ({
  hex: accentColorDto.hex,
  contrastHex: accentColorDto.contrastHex,
  isHighLuminance: accentColorDto.isHighLuminance,
});
