import type { allLocales } from "@/elements/localization/generated/locale-codes";

type Locale = (typeof allLocales)[number];

export const languageFallbackLocales: Record<string, Locale> = {
  en: "en-GB",
  de: "de-DE",
  es: "es-ES",
  fr: "fr-FR",
  it: "it-IT",
  pt: "pt-PT",
};
