import { createStore } from "zustand/vanilla";

interface BookingElementState {
  activePropertyId?: string | undefined;
  isDialogOpen: boolean;
}

export const bookingElementStore = createStore<BookingElementState>(() => ({
  isDialogOpen: false,
}));
