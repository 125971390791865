import { ky } from "@/elements/ky/ky";
import type { Widget, WidgetDto } from "@/elements/widget/widget";
import { mapWidgetFromDto } from "@/elements/widget/widget.mapper";

export const sendFetchWidgetRequest = async (
  widgetId: string,
): Promise<Widget> => {
  const widgetDto = await ky.get(`widgets/${widgetId}`).json<WidgetDto>();

  return mapWidgetFromDto(widgetDto);
};
