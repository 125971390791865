export enum WidgetElementsBookingElementButtonPositionAlignment {
  Start = "start",
  End = "end",
}

export interface WidgetElementsBookingElementButton {
  position: {
    x: WidgetElementsBookingElementButtonPositionAlignment;
    y: WidgetElementsBookingElementButtonPositionAlignment;
  };
}

export type WidgetElementsBookingElementButtonDto =
  WidgetElementsBookingElementButton;
