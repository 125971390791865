import { createStore } from "zustand/vanilla";

import type { Widget } from "@/elements/widget/widget";

interface WidgetState {
  widget?: Widget;
  isInitialized: boolean;
}

export const widgetStore = createStore<WidgetState>(() => ({
  isInitialized: false,
}));
