import { mapWidgetElementsBookingElementButtonFromDto } from "@/elements/widget/elements/booking-element/button/widget-elements-booking-element-button.mapper";
import { mapWidgetElementsBookingElementDialogFromDto } from "@/elements/widget/elements/booking-element/dialog/widget-elements-booking-element-dialog.mapper";
import type {
  WidgetElementsBookingElement,
  WidgetElementsBookingElementDto,
} from "@/elements/widget/elements/booking-element/widget-elements-booking-element";

export const mapWidgetElementsBookingElementFromDto = (
  widgetElementsBookingElementDto: WidgetElementsBookingElementDto,
): WidgetElementsBookingElement => ({
  button: mapWidgetElementsBookingElementButtonFromDto(
    widgetElementsBookingElementDto.button,
  ),
  dialog: mapWidgetElementsBookingElementDialogFromDto(
    widgetElementsBookingElementDto.dialog,
  ),
});
