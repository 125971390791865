import "@/elements/main";

interface Attributes {
  widgetId: string;
  propertyId: string | undefined;
}

const WIDGET_ID_DATA_ATTRIBUTE = "data-widget-id";
const PROPERTY_ID_DATA_ATTRIBUTE = "data-property-id";

const findScriptElement = (): HTMLOrSVGScriptElement => {
  const scriptElement = document.currentScript;

  if (!scriptElement) {
    throw new Error("Document was not currently executing any script");
  }

  return scriptElement;
};

const extractAttributesFromScriptElement = (
  scriptElement: HTMLOrSVGScriptElement,
): Attributes => {
  const widgetId = scriptElement.getAttribute(WIDGET_ID_DATA_ATTRIBUTE);

  if (!widgetId) {
    throw new Error(
      `Script element contained no '${WIDGET_ID_DATA_ATTRIBUTE}' attribute, or it was empty`,
    );
  }

  const propertyId =
    scriptElement.getAttribute(PROPERTY_ID_DATA_ATTRIBUTE) ?? undefined;

  return { widgetId, propertyId };
};

/**
 * If the DOM is already ready, the elements will be immediately injected.
 * Otherwise, an event listener is registered to inject the elements when the
 * DOM is ready.
 */
const injectElements = (attributes: Attributes) => {
  if (/complete|interactive|loaded/.test(document.readyState)) {
    insertHTML(attributes);
  } else {
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        insertHTML(attributes);
      },
      { once: true },
    );
  }
};

const insertHTML = ({ widgetId, propertyId }: Attributes) => {
  document.body.insertAdjacentHTML(
    "beforeend",
    `<bloom-booking-button ${propertyId ? `property-id=${propertyId}` : ""}>
    </bloom-booking-button><bloom-booking-dialog widget-id=${widgetId}></bloom-booking-dialog>`,
  );
};

try {
  const scriptElement = findScriptElement();

  const attributes = extractAttributesFromScriptElement(scriptElement);

  injectElements(attributes);
} catch (error) {
  console.error(
    error instanceof Error
      ? `Could not render a Bloom widget due to the following error: ${error.message}.`
      : error,
  );
}
