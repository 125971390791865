import { configureLocalization } from "@lit/localize";
import { matchSupportedLocale } from "match-supported-locale";

import {
  allLocales,
  sourceLocale,
  targetLocales,
} from "@/elements/localization/generated/locale-codes";
import { languageFallbackLocales } from "@/elements/localization/locale";

export const setDefaultLocale = () =>
  setLocale(getDefaultLocale() ?? sourceLocale);

const getDefaultLocale = () =>
  matchSupportedLocale(
    navigator.language,
    [...allLocales],
    languageFallbackLocales,
  );

const { setLocale } = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: (locale) => import(`./generated/locales/${locale}.ts`),
});
