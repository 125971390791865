import type { ReactiveController, ReactiveControllerHost } from "lit";

import type { Property } from "@/elements/property/property";
import type { WidgetElementsBookingElement } from "@/elements/widget/elements/booking-element/widget-elements-booking-element";
import type { Widget } from "@/elements/widget/widget";
import { sendFetchWidgetRequest } from "@/elements/widget/widget.api";
import { widgetStore } from "@/elements/widget/widget.store";
import {
  findWidgetPrimaryPropertyOrFail,
  findWidgetPropertyByIdOrFail,
} from "@/elements/widget/widget.utilities";

export class WidgetController implements ReactiveController {
  private host: ReactiveControllerHost;

  public widget: Widget;
  public isInitialized: boolean;

  public constructor(host: ReactiveControllerHost) {
    host.addController(this);
    this.host = host;
  }

  public get bookingElement(): WidgetElementsBookingElement {
    return this.widget.elements.bookingElement;
  }

  public get primaryProperty(): Property {
    return findWidgetPrimaryPropertyOrFail(this.widget);
  }

  public async initialize(widgetId: string) {
    const widget = await sendFetchWidgetRequest(widgetId);

    widgetStore.setState({ widget, isInitialized: true });
  }

  public findPropertyById(propertyId: string): Property {
    return findWidgetPropertyByIdOrFail(this.widget, propertyId);
  }

  public hostConnected() {
    this.widgetStoreUnsubscribe = widgetStore.subscribe(
      ({ widget, isInitialized }) => {
        if (widget) {
          this.widget = widget;
        }

        this.isInitialized = isInitialized;

        this.host.requestUpdate();
      },
    );
  }

  public hostDisconnected() {
    this.widgetStoreUnsubscribe();
  }

  private widgetStoreUnsubscribe: () => void;
}
