import { mapPropertyFromDto } from "@/elements/property/property.mapper";
import { mapWidgetElementsFromDto } from "@/elements/widget/elements/widget-elements.mapper";
import type { Widget, WidgetDto } from "@/elements/widget/widget";

export const mapWidgetFromDto = (widgetDto: WidgetDto): Widget => ({
  id: widgetDto.id,
  properties: widgetDto.properties.map(mapPropertyFromDto),
  rankOrderedPropertyIds: widgetDto.rankOrderedPropertyIds.map((propertyIds) =>
    propertyIds.map((propertyId) => propertyId.toString()),
  ),
  elements: mapWidgetElementsFromDto(widgetDto.elements),
});
