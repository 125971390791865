import { mapAccentColorFromDto } from "@/elements/accent-color/accent-color.mapper";
import { mapWidgetElementsBookingElementFromDto } from "@/elements/widget/elements/booking-element/widget-elements-booking-element.mapper";
import type {
  WidgetElements,
  WidgetElementsDto,
} from "@/elements/widget/elements/widget-elements";

export const mapWidgetElementsFromDto = (
  widgetElementsDto: WidgetElementsDto,
): WidgetElements => ({
  accentColor: widgetElementsDto.accentColour
    ? mapAccentColorFromDto(widgetElementsDto.accentColour)
    : undefined,
  bookingElement: mapWidgetElementsBookingElementFromDto(
    widgetElementsDto.bookingElement,
  ),
});
