import type {
  WidgetElementsBookingElementButton,
  WidgetElementsBookingElementButtonDto,
} from "@/elements/widget/elements/booking-element/button/widget-elements-booking-element-button";

export const mapWidgetElementsBookingElementButtonFromDto = (
  widgetElementsBookingElementButtonDto: WidgetElementsBookingElementButtonDto,
): WidgetElementsBookingElementButton => ({
  position: {
    x: widgetElementsBookingElementButtonDto.position.x,
    y: widgetElementsBookingElementButtonDto.position.y,
  },
});
