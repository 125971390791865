import type { ReactiveController, ReactiveControllerHost } from "lit";

import { bookingElementStore } from "@/elements/booking-element/booking-element.store";

export class BookingElementController implements ReactiveController {
  private host: ReactiveControllerHost;

  public activePropertyId: string | undefined;
  public isDialogOpen: boolean;

  public constructor(host: ReactiveControllerHost) {
    host.addController(this);
    this.host = host;
  }

  public setActivePropertyId(propertyId: string) {
    bookingElementStore.setState({ activePropertyId: propertyId });
  }

  public resetActivePropertyId() {
    bookingElementStore.setState({ activePropertyId: undefined });
  }

  public openDialog() {
    bookingElementStore.setState({ isDialogOpen: true });
  }

  public closeDialog() {
    bookingElementStore.setState({ isDialogOpen: false });
  }

  public hostConnected() {
    this.bookingElementStoreUnsubscribe = bookingElementStore.subscribe(
      ({ activePropertyId, isDialogOpen }) => {
        this.activePropertyId = activePropertyId;
        this.isDialogOpen = isDialogOpen;

        this.host.requestUpdate();
      },
    );
  }

  public hostDisconnected() {
    this.bookingElementStoreUnsubscribe();
  }

  private bookingElementStoreUnsubscribe: () => void;
}
